/** 
 * @param dir
 * dtu - dash to uppercase (phuket-town -> PHUKET_TOWN)
 * 
 * utd - uppercase to dash (PHUKET_TOWN -> phuket-town)
 * 
 * uts - uppercase to spaces (PHUKET_TOWN -> Phuket Town)
 */
export const tn = (name: string, dir: "dtu" | "utd" | "uts") =>
{
  switch(dir){
    case 'utd':
      name = name.replaceAll("_", "-").toLowerCase();
      break;
    case "dtu":
      name = name.replaceAll("-", "_").toUpperCase();
      break;
    case 'uts':
      name = name.replaceAll('_', ' ').split(' ').map(i => i.slice(0,1).toUpperCase() + i.slice(1)).join(' ');

  }
  return name;
}
    
