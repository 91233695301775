import { useEffect, useMemo, useState } from "react";
import { useCatalogFilter } from "./useCatalogFilter";
import { useAppSelector } from "../store/app";
import {
  IComplexPage,
  LCPhuketDistrict,
  LCSamuiDistrict,
  Landscape,
  LayoutObjectType,
  LayoutObjectTypeCatalog,
  OwnershipType,
  ViewsType,
} from "../types/complexPage";
import { CatalogQuery, CatalogQueryParam, ICatalogObject } from "../types/catalogPage";
import { useCatalogActions } from "../store/slices/catalog";
import { ICurrency } from "../types/currency";
import { useDebounce } from "./useDebounce";
import { IValueLabel } from "../types/mainPage";
import { useTranslation } from "next-i18next";
import { compareDates } from "../utils/compareDates";
import { predefinedPrices } from "../ui/blocks/page-specific/Catalog/CatalogFilter/constants";
import { tn } from "../utils/transformName";

const getPrices = (
  properties: ICatalogObject[],
  currency: ICurrency["value"],
): [number, number] => {
  const layoutsPrices = properties.map((p) => p.price);

  const defaultMinPrice = layoutsPrices[0]?.[currency] || 0;
  const result: [number, number] = layoutsPrices.reduce(
    (a, b) => {
      const price = b;
      if (price[currency] > a[1]) {
        a[1] = price[currency];
      }
      if (price![currency] < a[0]) {
        a[0] = price[currency];
      }
      return a;
    },
    [defaultMinPrice, defaultMinPrice],
  );
  return result;
};

const checkBathroom = (value: number[], filterValue?: CatalogQuery["bathroomAmount"]) => {
  if (
    !filterValue ||
    !filterValue?.length ||
    (Array.isArray(filterValue) && filterValue?.length === 3)
  )
    return true;

  if (typeof filterValue === "string") {
    if (+filterValue >= 3) {
      return value.some((val) => val >= 3);
    }
    return value.includes(+filterValue);
  }
  if (Array.isArray(filterValue)) {
    return (
      filterValue.some((val) => value.includes(+val)) ||
      (filterValue.includes("3") && value.some((val) => val >= 3))
    );
  }
};
const checkMinPrice = (value: number, filterValue?: number) => {
  if (!filterValue) return true;
  return value >= filterValue;
};
const checkMaxPrice = (value: number, filterValue?: number) => {
  if (!filterValue) return true;
  return value <= filterValue;
};

const checkButtonPrice = (
  value: number,
  currency: ICurrency["value"],
  filterValue?: CatalogQuery["priceButton"],
) => {
  if (!filterValue || !filterValue?.length) return true;
  if (typeof filterValue === "string") {
    const { minValue, maxValue } = predefinedPrices[currency][`price${filterValue}`];

    return value >= minValue && value <= maxValue;
  }
  if (Array.isArray(filterValue)) {
    return filterValue.some((priceVal) => {
      const { minValue, maxValue } = predefinedPrices[currency][`price${priceVal}`];
      return value >= minValue && value <= maxValue;
    });
  }
  return true;
};

const checkPropertyType = (
  value: LayoutObjectType[],
  filterValue?: LayoutObjectTypeCatalog | LayoutObjectTypeCatalog[],
) => {
  if (!filterValue) return true;
  if (typeof filterValue === "string") {
    return value.includes(filterValue.toUpperCase() as LayoutObjectType);
  }
  if (Array.isArray(filterValue)) {
    return value.some((val) =>
      filterValue.includes(val.toLowerCase() as LayoutObjectTypeCatalog),
    );
  }
  return false;
};

const checkOwnershipType = (
  value: CatalogQuery["ownershipType"],
  filterValue?: CatalogQuery["ownershipType"],
) => {
  if (!filterValue || filterValue === "BOTH" || filterValue.includes("BOTH")) return true;
  if (typeof filterValue === "string") {
    return value === filterValue;
  }
  return filterValue.includes(value as OwnershipType);
};

const checkPerfectInvest = (
  value?: boolean,
  filterValue?: CatalogQuery["perfectInvest"],
) => {
  if (!filterValue) return true;
  return value;
};

const checkLandscape = (value?: Landscape, filterValue?: CatalogQuery["landscape"]) => {
  if (!filterValue || !filterValue?.length || !value) return true;
  if (typeof filterValue === "string") {
    return value === filterValue;
  }
  if (Array.isArray(filterValue)) {
    return filterValue.some((val) => val === value);
  }
};

const checkStages = (
  value: IComplexPage["saleObject"]["constructionState"],
  filterValue?: CatalogQuery["buildingStages"],
) => {
  if (
    !filterValue ||
    !filterValue?.length ||
    (Array.isArray(filterValue) && filterValue?.length === 2)
  )
    return true;
  if (typeof filterValue === "string") {
    if (filterValue === "readyForMoveIn") {
      return value?.isReady;
    } else {
      return !value?.isReady;
    }
  }
};

const checkInvest = (
  value: ICatalogObject,
  filterValue?: CatalogQuery["investAttractiveness"],
) => {
  if (
    !filterValue ||
    !filterValue?.length ||
    (Array.isArray(filterValue) && filterValue?.length === 3)
  )
    return true;
  if (typeof filterValue === "string") {
    return value?.[filterValue];
  }
  return filterValue.some((val) => val in value);
};

const checkViews = (value: ViewsType[], filterValue?: CatalogQuery["view"]) => {
  if (
    !filterValue ||
    !filterValue?.length ||
    (Array.isArray(filterValue) && filterValue?.length === 3)
  )
    return true;
  if (typeof filterValue === "string" && value) {
    if (value.includes(filterValue)) {
      return value.includes(filterValue);
    }
  }
  if (Array.isArray(filterValue) && value) {
    return filterValue.some((val) => value.includes(val));
  }
};

const checkDistrict = (
  value: LCPhuketDistrict | LCSamuiDistrict,
  filterValue?: CatalogQuery["phuketDistrict" | "samuiDistrict"],
) => {
  if (!filterValue || !filterValue?.length || !value || filterValue === "all") {
    return true;
  }
  if (typeof filterValue === "string") {
    return value === filterValue;
  }
  return filterValue.some((val) => val.includes(value));
};

const checkBedrooms = (value: string[], filterValue?: CatalogQuery["bedrooms"]) => {
  if (
    !filterValue ||
    !filterValue?.length ||
    (Array.isArray(filterValue) && filterValue?.length === 5)
  )
    return true;
  if (typeof filterValue === "string") {
    if (+filterValue >= 4) {
      return value.some((val) => +val >= 4);
    }
    return value.some((val) => val === filterValue);
  }
  if (Array.isArray(filterValue)) {
    return (
      filterValue.some((val) => value.includes(val)) ||
      (filterValue.includes("4") && value.some((val) => +val >= 4))
    );
  }
  return false;
};

const checkSearchQuery = (
  value: { saleName: string; developerName: string; managementCompanyName: string },
  filterValue?: string,
) => {
  if (!filterValue || !filterValue.length) return true;
  const saleName = value?.saleName?.toLowerCase();
  const managementCompanyName = value?.managementCompanyName?.toLowerCase();
  const developerName = value?.developerName?.toLowerCase();
  const searchValue = decodeURIComponent(filterValue)?.toLowerCase().split(" ");
  return (
    (managementCompanyName &&
      searchValue.every((val) => managementCompanyName.includes(val))) ||
    (developerName && searchValue.every((val) => developerName.includes(val))) ||
    (saleName && searchValue.every((val) => saleName.includes(val)))
  );
};

const checkFacilities = (
  value: ICatalogObject,
  filterValue?: CatalogQuery["facilities"],
) => {
  if (!filterValue || !filterValue?.length) return true;
  if (typeof filterValue === "string") {
    return value[filterValue];
  }
  if (Array.isArray(filterValue)) {
    return filterValue.every((val) => value[val]);
  }
};

const checkBeachDistance = (
  value: ICatalogObject["distanceToBeach"],
  filterValue?: CatalogQuery["beachDistance"],
) => {
  if (!filterValue || !filterValue.length || filterValue.length === 3) return true;
  const compareDistance = (filterValue: CatalogQuery["beachDistance"]) => {
    if (filterValue === "3") {
      return value?.method === "CAR" || value?.minutes >= 20;
    } else if (filterValue === "2") {
      return value?.method === "FOOT" && value?.minutes >= 10 && value?.minutes < 20;
    } else if (filterValue === "1") {
      return value?.method === "FOOT" && value?.minutes < 10;
    }
    return false;
  };
  if (typeof filterValue === "string") {
    return compareDistance(filterValue);
  }
  if (Array.isArray(filterValue)) {
    return filterValue.some((fv) => {
      return compareDistance(fv);
    });
  }
  return false;
};
const checkBeaches = (
  values: string[],
  filterValue?: CatalogQuery["phuketBeaches" | "samuiBeaches"],
) => {
  if (!values || !filterValue || !values.length || !filterValue.length) return true;
  if (typeof filterValue === "string") {
    return values.includes(filterValue);
  }
  if (Array.isArray(filterValue)) {
    return filterValue.some((fv) => values.includes(fv));
  }
};

const sortProperties = (
  properties: ICatalogObject[],
  sort: CatalogQuery["sort"],
  sortDir: CatalogQuery["sortDir"],
) => {
  if (!sort || sort === "default") return properties;
  return [...properties].sort((a, b) => {
    const firstItem = sortDir === "desc" ? a : b;
    const secondItem = sortDir === "desc" ? b : a;

    if (sort === "price") {
      return firstItem.price.usd - secondItem.price.usd;
    }
    if (sort === "distance") {
      return secondItem.distanceToBeach?.minutes - firstItem.distanceToBeach?.minutes;
    }
    if (sort === "date") {
      if (sortDir === "desc") {
        return firstItem.constructionState?.offPlan
          ? 1
          : secondItem.constructionState?.offPlan
            ? -1
            : compareDates(
                  firstItem.constructionState?.constructionDate,
                  secondItem.constructionState?.constructionDate,
                )
              ? 1
              : -1;
      }
      return firstItem.constructionState?.offPlan &&
        !secondItem.constructionState?.offPlan
        ? 1
        : !firstItem.constructionState?.offPlan && secondItem.constructionState?.offPlan
          ? -1
          : compareDates(
                firstItem.constructionState?.constructionDate,
                secondItem.constructionState?.constructionDate,
              )
            ? -1
            : 1;
    }
    if (sort === "area") {
      return firstItem.area > secondItem.area ? 1 : -1;
    }
    return -1;
  });
};

export const getFilteredProperties = (
  properties: ICatalogObject[],
  query: CatalogQuery,
  currency: ICurrency["value"],
) => {
  const {
    maxPrice,
    minPrice,
    ownershipType,
    perfectInvest,
    propertyType,
    videoReview,
    landscape,
    buildingStages,
    investAttractiveness,
    view,
    phuketDistrict,
    samuiDistrict,
    bathroomAmount,
    bedrooms,
    facilities,
    priceButton,
    beachDistance,
    phuketBeaches,
    samuiBeaches,
    q,
  } = query;

  const queryPropertyType = Array.isArray(propertyType)
    ? propertyType.map((i) => (i === "flat" ? "apartment" : i))
    : propertyType === "flat"
      ? "apartment"
      : propertyType;

  const result: ICatalogObject[] = [];
  for (let i = 0; i < properties.length; i++) {
    const saleObject = properties[i];

    const objectPrice = saleObject.price;
    const price = objectPrice![currency];
    const propertyTypes = [saleObject.type];
    const objectBathrooms = [saleObject.bathrooms];

    const objectBedrooms = [String(saleObject.bedrooms)];
    const beaches = saleObject.beaches.map((b) => tn(b, "utd"));
    const districts = saleObject.districts;
    if (
      (minPrice ? checkMinPrice(price, minPrice ? +minPrice : undefined) : true) &&
      (maxPrice ? checkMaxPrice(price, maxPrice ? +maxPrice : undefined) : true) &&
      (priceButton ? checkButtonPrice(price, currency, priceButton) : true) &&
      (queryPropertyType ? checkPropertyType(propertyTypes, queryPropertyType) : true) &&
      (ownershipType
        ? checkOwnershipType(saleObject.ownershipType, ownershipType)
        : true) &&
      (perfectInvest
        ? checkPerfectInvest(saleObject.perfectForInvestment, perfectInvest)
        : true) &&
      (videoReview ? saleObject.hasVideo : true) &&
      (landscape ? checkLandscape(saleObject.landscape, landscape) : true) &&
      (buildingStages
        ? checkStages(saleObject.constructionState, buildingStages)
        : true) &&
      (investAttractiveness ? checkInvest(saleObject, investAttractiveness) : true) &&
      (view ? checkViews(saleObject.views, view) : true) &&
      ((samuiDistrict &&
        saleObject.region === "samui" &&
        checkDistrict(districts[0], samuiDistrict)) ||
        (phuketDistrict &&
          saleObject.region === "phuket" &&
          checkDistrict(districts[0], phuketDistrict)) ||
        (!samuiDistrict && !phuketDistrict)) &&
      (bathroomAmount ? checkBathroom(objectBathrooms, bathroomAmount) : true) &&
      (bedrooms ? checkBedrooms(objectBedrooms, bedrooms) : true) &&
      (q
        ? checkSearchQuery(
            {
              saleName: saleObject.name,
              developerName: saleObject.developerName || "",
              managementCompanyName: saleObject.managementCompanyName || "",
            },
            q,
          )
        : true) &&
      (beachDistance
        ? checkBeachDistance(saleObject.distanceToBeach, beachDistance)
        : true) &&
      ((samuiBeaches &&
        saleObject.region === "samui" &&
        checkBeaches(beaches, samuiBeaches)) ||
        (phuketBeaches &&
          saleObject.region === "phuket" &&
          checkBeaches(beaches, phuketBeaches)) ||
        (!samuiBeaches && !phuketBeaches)) &&
      (facilities ? checkFacilities(saleObject, facilities) : true)
    ) {
      result.push(saleObject);
    }
  }
  return result;
};

export const useFilteredProperties = (properties: ICatalogObject[]) => {
  const { query, deleteParam } = useCatalogFilter();
  const [isLoading, setIsLoading] = useState(true);
  const [debouncedFilteredProperties, setDebouncedFilteredProperties] =
    useState(properties);

  const { setPriceRange, setFilteredPriceRange } = useCatalogActions();

  const [minPrice, maxPrice] = useAppSelector((state) => state.catalog.prices);
  const [filteredMinPrice, filteredMaxPrice] = useAppSelector(
    (state) => state.catalog.filteredPrices,
  );
  const currency = useAppSelector((state) => state.settings.currency.value);

  const setPrices = useDebounce(
    (prices: [number, number]) => setFilteredPriceRange(prices),
    250,
  );

  useEffect(() => {
    const priceRange = getPrices(properties, currency);
    setPriceRange(priceRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties, currency, setPriceRange]);

  const debouncedDeleteMinPrice = useDebounce(() => {
    if (filteredMinPrice === minPrice && filteredMinPrice > 0) {
      deleteParam("minPrice");
    }
  }, 500);

  const debouncedDeleteMaxPrice = useDebounce(() => {
    if (filteredMaxPrice === maxPrice && filteredMaxPrice > 0) {
      deleteParam("maxPrice");
    }
  }, 500);

  useEffect(() => {
    debouncedDeleteMinPrice();
  }, [debouncedDeleteMinPrice, deleteParam, filteredMinPrice, minPrice]);

  useEffect(() => {
    debouncedDeleteMaxPrice();
  }, [debouncedDeleteMaxPrice, deleteParam, filteredMaxPrice, maxPrice]);

  const predefinedQuery = useAppSelector((state) => state.catalog.filters);

  const filteredProperties = useMemo(() => {
    setIsLoading(true);
    const priceRange = getPrices(properties, currency);
    if (Object.values(query).length === 0) {
      setTimeout(() => {
        setIsLoading(false);
      }, 250);
      setPrices(priceRange);
      return properties;
    }
    const newProperties = getFilteredProperties(
      properties,
      {
        ...query,
      },
      currency,
    );

    const { sort, sortDir } = query;
    const sortedProperties = sortProperties(newProperties, sort, sortDir);
    const filteredPriceRange = getPrices(sortedProperties, currency);

    setPrices(filteredPriceRange);
    return sortedProperties;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, currency, properties.length, predefinedQuery]);

  const fakeLoading = useDebounce(() => {
    setDebouncedFilteredProperties(filteredProperties);
    setIsLoading(false);
  }, 250);

  useEffect(() => {
    fakeLoading();
    if (query.modal) return setIsLoading(false);
  }, [fakeLoading, filteredProperties, currency, query]);

  return { debouncedFilteredProperties, isLoading };
};

export const useTranslateAndDetectDisabled = () => {
  const properties = useAppSelector((state) => state.catalog.properties);
  const currency = useAppSelector((state) => state.settings.currency.value);
  const { query } = useCatalogFilter();
  const { t } = useTranslation("index");
  return (arr: IValueLabel[], catalogQuery: CatalogQueryParam, addId?: boolean) => {
    const isLocations =
      catalogQuery === "phuketDistrict" ||
      catalogQuery === "samuiDistrict" ||
      catalogQuery === "island" ||
      catalogQuery === "phuketBeaches" ||
      catalogQuery === "samuiBeaches";
    return arr.map((item) => {
      const getAddedQuery = (): CatalogQuery => {
        if (!query[catalogQuery]) return { [catalogQuery]: item.value };
        if (typeof query[catalogQuery] === "string") {
          return { [catalogQuery]: [query[catalogQuery], item.value] };
        }
        if (Array.isArray(query[catalogQuery])) {
          return {
            [catalogQuery]: Array.from(
              new Set([...Array.from(query[catalogQuery] as string[]), item.value]),
            ),
          };
        }
        return {};
      };
      const addedQuery = getAddedQuery();

      const newQuery = { ...query };
      if (catalogQuery === "samuiDistrict") {
        delete newQuery.phuketDistrict;
        delete newQuery.samuiBeaches;
        delete newQuery.phuketBeaches;
      }
      if (catalogQuery === "phuketDistrict") {
        delete newQuery.samuiDistrict;
        delete newQuery.samuiBeaches;
        delete newQuery.phuketBeaches;
      }
      if (catalogQuery === "samuiBeaches") {
        delete newQuery.phuketDistrict;
        delete newQuery.samuiDistrict;
        delete newQuery.phuketBeaches;
      }
      if (catalogQuery === "phuketBeaches") {
        delete newQuery.phuketDistrict;
        delete newQuery.samuiDistrict;
        delete newQuery.samuiBeaches;
      }

      /* check if there's no other value of this type initially */
      const prefilteredInitialResult = getFilteredProperties(
        properties,
        { ...newQuery, [catalogQuery]: item.value },
        currency,
      );
      /* check if it's available with other value of this type (e.g. views: PLAIN + new value: HILLS) */
      const prefilteredResult = getFilteredProperties(
        properties,
        { ...newQuery, ...addedQuery },
        currency,
      );

      return {
        ...item,
        label: isLocations ? t(`locations.${item.label}`) : t(item.label),
        isDisabled:
          prefilteredResult.length === 0 || prefilteredInitialResult.length === 0,
        ...(addId && { id: String(item.value) }),
      };
    });
  };
};
